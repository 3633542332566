export const chatConfigColors = {
    dark:{
        WorkflowFormBgColor: '#292929',
        WorkflowFormColor: '#292929',
        WorkflowFormBorderColor: '#FFFFFF38',
        WorkflowFormShadowColor: '#FFFFFF40',
        WorkflowFormHeadingColor: '#ECECEC',
        WorkflowFormLabelColor: "#B4B4B4",
        InputBgColor: "transparent",
        InputBorderColor: "#FFFFFF4D",
        InputTextColor: "#ECECEC",
        cancelBtnBorderColor: "#7272724D",
        FormCrossIconBgColor: '#3A3A3A',
        FormCrossIconHoverBgColor:'#c5c5c545',
        SelectBgColor: '#2D2D2D',
        tableTdColor: '#ECECEC',
        tableTrBgColor: '#292929',
        tableThtextColor:'#B4B4B4',
        tableHeadingBgColor: '#007AA0',
        tableHeadingTextColor: '#ffffff',
        DeepAnalysisTextBgColor: '#007AA012',
        DeepAnalysisTextBorderColor: '#007AA0',
        tableOuterBoderColor: '#383838',
        tableOuterBGColor: '#1E1E1E',
        CreateBtnBgColor: '#FFFFFF38',
        CreateBtnBorderColor: '#D2D2D22E',
        CreateBtnShadowColor: '#00000029',
        actionBtnBgColor: '#FFFFFF0D',
        actionBtnBorderColor: '#FFFFFF38',
        HrBorderColor: '#383838',
        actionBtnHoverBgColor: '#000',
        WorkflowFormHeaderBorderColor: '#383838',
        WorkFlowHeaderSvgIcon:'#fff',
        FormSubmitBGColor:'#3A3A3A',
        CancelBtnTextColor:'#ECECEC',
        tabInActiveTextColor: '#B4B4B4',
        tabInActiveBgColor : '#3A3A3A',
        paginationActiveBgColor: '#525252',
        actionBtnSvgColor: '#fff',
        labelTextColor: '#fff',
        OptionItemHoverColor:'#505050',
        CreateBtnHoverBgColor:'#bdbdbd38',
        DropdownBgColor:'#1b1b32',
        DropDownItemHoverColor:'#323446',
        AgentCardBgColor:'#1b1b32',
        PopupOverlayColor:'#181616BF',
    },

    light:{
        WorkflowFormBgColor: '#EEEFF3',
        WorkflowFormColor: '#EFF3FD',
        WorkflowFormBorderColor: '#D9D9D9',
        WorkflowFormShadowColor: '#00000026',
        WorkflowFormHeadingColor: '#2C2C2C',
        WorkflowFormLabelColor: "#727272",
        InputBgColor: "#fff",
        InputBorderColor: "#0000001A",
        InputTextColor: "#2C2C2C",
        cancelBtnBorderColor: "#727272",
        FormCrossIconBgColor: '#0000000d',
        FormCrossIconHoverBgColor:'#41414145',
        SelectBgColor: '#fff',
        tableTdColor: '#2C2C2C',
        tableTrBgColor: '#eff3fd',
        tableThtextColor:'#727272',
        tableHeadingBgColor: '#bae3ff',
        tableHeadingTextColor: '#2C2C2C',
        DeepAnalysisTextBgColor: '#F0F1FA',
        DeepAnalysisTextBorderColor: 'transparent',
        tableOuterBoderColor: '#d9d9d9',
        tableOuterBGColor: 'transparent',
        CreateBtnBgColor: '#2058E8',
        CreateBtnBorderColor: '#2058E8',
        CreateBtnShadowColor: '#2264E51F',
        actionBtnBgColor: 'transparent',
        actionBtnHoverBgColor: '#f2f2f2',
        actionBtnBorderColor: '#EAEAEA',
        HrBorderColor: '#0000001A',
        WorkflowFormHeaderBorderColor: '#DCE0E9',
        WorkFlowHeaderSvgIcon:'#787878',
        FormSubmitBGColor:'#1d4ee5',
        CancelBtnTextColor:'#727272',
        tabInActiveTextColor: '#727272',
        tabInActiveBgColor : '#FFFFFF',
        paginationActiveBgColor: '#2058e9',
        actionBtnSvgColor: '#727272',
        labelTextColor: '#727272',
        OptionItemHoverColor:'#f0f0f0',
        CreateBtnHoverBgColor:'#0642db',
        DropdownBgColor:'#fff',
        DropDownItemHoverColor:'#f1f1f1',
        AgentCardBgColor:'transparent',
        PopupOverlayColor:'#FFFFFF66',
    }
};