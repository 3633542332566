import React, { useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import { useTypedSelector } from 'hooks/use-typed-selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FeedbackButton } from 'components/common/styles';

const SentryFeedback: React.FC = () => {
    const theme = useTypedSelector(({ auth }) => auth.user.settings.theme);
    const environment = useTypedSelector(({ siteSettings }) => (siteSettings as any).environment);
    const [feedbackInstance, setFeedbackInstance] = useState<any>(null);
    const url = window.location.href;
    const { hostname } = new URL(url);

    useEffect(() => {
        if (hostname !== "localhost") {
            const feedback = Sentry.feedbackIntegration({
                colorScheme: theme,
                buttonLabel: "Feedback",
                autoInject: false,
            });
            setFeedbackInstance(feedback);

            const feedback_component = document.querySelector('#sentry_feedback');
            if (feedback_component && feedback) {
                feedback.attachTo(feedback_component, {
                    formTitle: 'Report a Bug!',
                });
            }
        }

        return () => {
            if (feedbackInstance) {
                feedbackInstance.remove();
            }
        };
    }, [hostname, theme, environment]);

    const hideFeedback = (e: React.MouseEvent<HTMLOrSVGElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (feedbackInstance) {
            feedbackInstance.closeDialog();
            feedbackInstance.remove();
            setFeedbackInstance(null);
        }
        const feedback_component: HTMLButtonElement | null = document.querySelector('#sentry_feedback');
        if (feedback_component) {
            feedback_component.style.display = "none";
        }
    };

    if (environment === 'dev' || environment === 'stage' || environment === 'prod') {
        if (hostname !== "localhost") {
            return (
                <FeedbackButton id="sentry_feedback" className='btn'>
                    Report a Bug!
                    <FontAwesomeIcon
                        icon={faXmark}
                        size={`2x`}
                        inverse={theme === "light" ? false : true}
                        onClick={hideFeedback}
                    />
                </FeedbackButton>
            );
        }
    }

    return null;
};

export default SentryFeedback;
