import { Dispatch } from "redux";
import { API, graphqlOperation } from "aws-amplify";
import { alert } from "./customRouter";
import { eventReceiver } from "graphQL/subscriptions";
import { Event, EventType, EventTypes } from "types/eventReciever";
import { GraphQLSubscription } from '@aws-amplify/api';
import { ActionType } from "state/action-types";
import { EventHandler as HumaEventHandler } from "huma-workflow-react-package";
import { chatAgents, Command } from "types/chat";
import { setActiveAgent, setSources } from "./AgentActions";
import { refetchChatConfiguration } from "./ChatConfigurationAction";
import { messageBlocked, messageInitializing, parseMetadata, subMessageProcessing, threadCreated, threadDeleted, threadJoined, threadRenamed } from "./ChatEventActions";

const showAlert = (event:Event) => {
  return async (dispatch: Dispatch<any>,getState:()=>any) => {
    const error = ["Failed","Failure","Error","Exception"];
    if(event.user_id.includes(getState().auth.user.user_id) && event.content.message){
      dispatch(alert(`${event.content.message}`, {position:"bottom-left"}, error.includes(event.content.type)?"error":"success"));
    }
  }
}
export const updateKnowledgeBaseStatus = (event:Event) => {
  return async (dispatch: Dispatch<any>,getState:()=>any) => {
    HumaEventHandler.emit(event.event_type as any,event);
    const agents = getState().agent.agents;
    const metadata = parseMetadata(event.content.metadata);
    const active_agent = getState().agent.selected_agent;
    const sources = getState().agent.sources;
    const updated_agents = agents.map((agent:chatAgents)=>{
      agent.config.dataSources = agent.config.dataSources?.map((source:Command)=>{
        if(source.id===metadata.name){
          return {
            ...source,
            status: metadata.status
          }
        }
        return source;
      })
      if(active_agent?.agent_name==agent.agent_name){
        dispatch(setActiveAgent(agent))
      }
      return agent;
    })

    const updated_sources = sources.map((source:Command)=>{
      if(source.id===metadata.name){
        return {
          ...source,
          status: metadata.status
        }
      }
      return source;
    })
    dispatch({
      type: ActionType.REFETCH_CHAT_CONFIG,
      payload: updated_agents
    });
    dispatch(setSources(updated_sources));
    dispatch(showAlert(event));
  }
}

export const updateCustomerNotices = (event:Event) => {
  
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    const currentNotice = getState().siteSettings?.customer_notice;
    const metadata = parseMetadata(event.content?.metadata);
    const newNotice = metadata?.customer_notice;
    if (newNotice !== currentNotice) {
      dispatch({
        type: ActionType.UPDATE_CUSTOMER_NOTICE,
        payload: newNotice
      });
      dispatch({
        type: ActionType.UPDATE_SHOW_CUSTOMER_NOTICE,
        payload: true
      })
      dispatch({
        type: ActionType.UPDATE_CUSTOMER_NOTICE_DISMISSED,
        payload: false
      })

      dispatch(showAlert(event));
    }
  }
}

// chat event handlers
const chatEventHandlers: Record<EventTypes, (event: Event) => any>  = {
  [EventType.THREAD_CREATED] : threadCreated,
  [EventType.THREAD_RENAMED] : threadRenamed,
  [EventType.THREAD_CLOSED] : threadDeleted,
  [EventType.THREAD_JOINED] : threadJoined,
  [EventType.THREAD_MESSAGE_INITIALIZING] : messageInitializing,
  [EventType.THREAD_MESSAGE_BLOCKED] : messageBlocked,
  [EventType.THREAD_MESSAGE_PROCESSING] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_DONE] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_FAILED] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_SUB_MESSAGE_INITIALIZING] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_SUB_MESSAGE_PROCESSING] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_SUB_MESSAGE_DONE] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_SUB_MESSAGE_FAILURE] : subMessageProcessing,
  [EventType.THREAD_MESSAGE_SUB_MESSAGE_RETRY] : subMessageProcessing,
  [EventType.KNOWLEDGE_BASE_STATUS_UPDATE] : updateKnowledgeBaseStatus,
  [EventType.CHAT_CONFIG_MODIFIED] : refetchChatConfiguration,
  [EventType.CUSTOMER_NOTICE_UPDATED] : updateCustomerNotices,
};

export const dispatchEvent = (eventType:EventTypes, eventData:Event) => {
  return async (dispatch: Dispatch<any>) => {
    const chatEventhandler = chatEventHandlers[eventType];
    const workflowEventTypes = [ 'workflow' , 'knowledge_base']
    const isWorkflowEvent = workflowEventTypes.some(type => eventType.startsWith(type));
    if (chatEventhandler) {
      dispatch(chatEventhandler(eventData));
    } else if (isWorkflowEvent) {
      HumaEventHandler.emit(eventType as any,eventData);
      dispatch(showAlert(eventData));
    } else {
      dispatch(subMessageProcessing(eventData));
      console.warn(`No handler found for event type: ${eventType}`);
    }
  }
};


export const subscribeEventRecieverAction = () => {
  return async (dispatch: Dispatch<any>, getState: () => any) => {
    try {
      const setupSubscription = async () => {
        const user_id = getState().auth.user.user_id;
        const subscription = getState().chat.subscription.eventReceiver;

        // Return early if there's already an active subscription
        if (subscription && !subscription.closed || !getState().auth.signedIn) {
          return;
        }
        if(subscription){
          subscription.unsubscribe();
        }

        const sub = API.graphql<GraphQLSubscription<{eventReceiver: Event}>>(
          graphqlOperation(eventReceiver, { user_id })
        ).subscribe({
          next: async (response) => {
            try {
              const event = response.value?.data?.eventReceiver;
              if (event) {
                console.log("[event from eventReceiver]", event);
                dispatch(dispatchEvent(event.event_type, event));
              }
            } catch (error) {
              console.error("Error processing event:", error);
              dispatch(alert("Error processing event"));
            }
          },
          error: (error) => {
            console.error("Subscription error:", error);
            dispatch(alert("Subscription error occurred"));
            // Optionally attempt to reconnect here
          },
          complete: () => {
            console.log("Subscription completed");
            // Optionally attempt to reconnect here
          }
        });

        dispatch(setSubscriptionAction({ name: "eventReceiver", value: sub }));
        return sub
      };

      const CONNECTION_CHECK_INTERVAL = 5000; // 5 seconds
      let connectionCheckTimeout: ReturnType<typeof setTimeout>;

      const startConnectionCheck = async () => {
        // Clear any existing timeout
        if (connectionCheckTimeout) {
          clearTimeout(connectionCheckTimeout);
        }
        const checkConnection = async () => {
          const subscription = getState().chat.subscription.eventReceiver;
          try {
            // if there is no subscription in redux and the network is online then we try to setup a new subscription
            // or if there is a subscription in redux but it is closed and the network is online then we try to setup a new subscription
            if (!subscription || (subscription && subscription.closed) && navigator.onLine) {
              console.log("No active subscription found, attempting to reconnect...");
              await setupSubscription();
            }
          } catch (error) {
            console.error("Error checking connection:", error);
          }
          // Schedule next check only after current check (and potential reconnection) is complete
          connectionCheckTimeout = setTimeout(checkConnection, CONNECTION_CHECK_INTERVAL);
        };
        // Start the first check
        await checkConnection();
      };

      // Debounce the reconnection attempt
      const RECONNECT_DELAY = 2000;
      let reconnectTimeout: ReturnType<typeof setTimeout>;

      const handleOnline = () => {
        console.log("Network is online, waiting to reconnect...");
        clearTimeout(reconnectTimeout);
        reconnectTimeout = setTimeout(() => {
          console.log("Reconnecting after delay...");
          setupSubscription();
        }, RECONNECT_DELAY);
      };

      // Cleanup previous listener before adding new one
      window.removeEventListener('online', handleOnline);
      window.addEventListener('online', handleOnline);

      // Initial subscription setup
      await setupSubscription();
      startConnectionCheck();

      // Optional: Return cleanup function
      return () => {
        window.removeEventListener('online', handleOnline);
        clearTimeout(reconnectTimeout);
        clearTimeout(connectionCheckTimeout);
        const subscription = getState().chat.subscription.eventReceiver;
        if (subscription) {
          subscription.unsubscribe();
        }
      };

    } catch (error) {
      console.error("Subscription setup error:", error);
      dispatch(alert("Could not subscribe to event receiver"));
    }
  };
};

export const setSubscriptionAction = (sub:{name:string, value: any}) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch({
      type: ActionType.SET_SUBSCRIPTION,
      payload: sub
    })
  }
}