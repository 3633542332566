import { ActionType } from "state/action-types"
import { Dispatch } from "redux";
import {
  getDifferentVisual,
  getPreviousAnswer,
  getNewTableAnswer,
  createAlias,
  getAlias,
  getAliasByName,
  deleteAlias,
  updateAlias,
  tableSuggestions,
  getAliasSchema,
  aliasSuggestion,
  aliasSearch,
  fetchingTableIndexedStatus
} from "services/answer";
import { RootState } from "state";
import { updateUserHistoryEntry, updateUserHistoryVisualByUtteranceId } from "./UserHistoryActions";
import { updateDataTransforming } from "./ActionBarActions";
import { alert } from "./customRouter";
import { compareQuestions, currentQuestionTimestamp } from "./SubscriptionHistoryAction";
import allSchema from 'components/Config/SearchAlias/schema/all-schemas.json'
import { sendMessageAction, toggleAnswerModalAction } from "./ChatActions"
import { keyMap } from "components/Table/utils";
import { sendNewMessagePayload } from "types/chatv2";
const useFixture = false
const PAGE_LIMIT = 200;
export const hydrateAnswerAction = ({
  question,
  utteranceId,
  subscribed,
  subscribed_id,
  visual,
  job_id = null,
  compute_route = "",
  fetch_history = [],
  isHistory = false,
  time_stamp,
  is_starred,
  pdf_status
}: any) => {
  return {
    type: ActionType.HYDRATE_ANSWER,
    payload: {
      question,
      utteranceId,
      subscribed,
      subscribed_id,
      fetch_history,
      isHistory,
      visual,
      job_id,
      compute_route,
      time_stamp,
      is_starred,
      pdf_status,
      reportExportStatus: [],
    },
  };
};

export const getSelectedType = (visual_type: string) => {
    return async (dispatch: Dispatch , getState: ()=>RootState) => {
        try {
            dispatch(updateDataTransforming(true));
            const state: RootState = getState()
            const question = state.answer.question
            const utteranceId = (state.answer.visual as any).utteranceId
            const possibleTypes = (state.answer.visual as any).possibleTypes
            const fetch_history = (state.answer.fetch_history)
            const subscribed_id = (state.answer.subscribedQuestionUtterenceId)
            const subscribed =(state.answer.subscribed);
            const time_stamp = (state.answer.time_stamp);
            const  isHistory = (state.answer.isHistory);
            const response = await getDifferentVisual(
                question,
                utteranceId ,
                visual_type,
                possibleTypes
            );
            if (response.status === 200) {
                const {  visual } = response.body;
                dispatch(compareQuestions(false));
                dispatch(updateUserHistoryVisualByUtteranceId(utteranceId , visual));
                dispatch(hydrateAnswerAction({ question: question, utteranceId, visual ,fetch_history,subscribed,subscribed_id,time_stamp ,isHistory})) 
                dispatch(updateDataTransforming(false));
                dispatch(currentQuestionTimestamp(time_stamp));
                // dispatch(updateLayout('horizontal'));
                const dataLength = visual?.data?.length || visual?.data?.data?.length
                dispatch(tableSliderData([0,dataLength]))
            } else {
                dispatch(updateDataTransforming(false));
            }
        } catch (error) {
            dispatch(updateDataTransforming(false));
            dispatch(alert());
        }
    }
}


export const fetchNewTableAction = (page_no: number, is_update:boolean, setIsBottom: (e: boolean) => void) => {
     return async (dispatch: Dispatch, getState: any) => {
        try {
            const {visual, answer_copy,tableSliderRange} = getState().answer;
            const checkTotalRecords = visual.total_records;
            setIsBottom(true);
            if (checkTotalRecords === visual.data.data.length){
              return;
            }
            newTableRecordLoading(true, dispatch);
            const response = await getNewTableAnswer(page_no, visual.utteranceId);
                if (response.status === 200) {
                const addData = response.body;
                const updatesData = [...visual.data.data , ...addData.data.data];
                const updatesRow = [...visual.data.rows , ...addData.data.rows];
                const payload = {
                    "data": updatesData,
                    "rows": updatesRow,
                    "headers": visual.data.headers,
                    "weights": visual.data.weights
                }
                if (!is_update) {
                    dispatch({
                        type: ActionType.FETCH_NEWTABLE_COMPLETED ,
                        payload: payload,
                    });
                }
                 else {
                    answer_copy['visual']['data'] = payload;
                    dispatch({
                        type: ActionType.UPDATE_NEWTABLE_VALUE ,
                        payload: payload
                    });
                    dispatch({
                        type: ActionType.HYDRATE_ANSWER_COPY ,
                        payload: answer_copy
                    });
                    setIsBottom(false);
                }
                const [ startRange ] = tableSliderRange;
                const newSliderRange = [ startRange , updatesData.length ];
                dispatch(tableSliderData(newSliderRange));
                if(addData.data.data.length === 0 || addData.data.data.length < 500) {
                    dispatch({
                        type: ActionType.UPDATE_NEWTABLE_HAS_MORE,
                        payload: false
                    });
                }
                newTableRecordLoading(false, dispatch);
                setIsBottom(false);
            } else {
                newTableRecordLoading(false, dispatch);
                setIsBottom(false);
                dispatch(alert("Please try to load table answer later"));
            }
        } catch (error) {
            newTableRecordLoading(false, dispatch);
            setIsBottom(false);
            dispatch(alert("Please try to load table answer later!"));
        }
    }
}

export const newTableRecordLoading = async(newTableRecordLoading:boolean, dispatch:Dispatch) =>{
    dispatch({
        type: ActionType.NEWTABLE_RECORD_LOADING,
        payload: newTableRecordLoading
    })
}

export const updateInnerRadius = (value: number) => {
  return {
    type: ActionType.UPDATE_INNER_RADIUS,
    payload: value,
  };
};

export const updatePadding = (value: number) => {
  return {
    type: ActionType.UPDATE_PADDING_FOR_BAR_CHART,
    payload: value,
  };
};

export const updateShowLegend = (value: boolean) => {
  return {
    type: ActionType.UPDATE_SHOW_LEGEND,
    payload: value,
  };
};

export const updateFontSize = (value: number) => {
  return {
    type: ActionType.UPDATE_TEXT_FONT_SIZE,
    payload: value,
  };
};

export const subscribeCurrentQuestion = (value: boolean) => {
  return {
    type: ActionType.UPDATE_SUBSCRIPTON_STATUS,
    payload: value,
  };
};

export const addSubscribedQuestionUtterenceID = (_id: string | undefined) => {
  return {
    type: ActionType.ADD_UTTERENCE_ID,
    payload: _id,
  };
};

export const updateRangeForBarVisual = (value: Array<number>) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.UPDATE_BARCHART_REVERSE,
      payload: false,
    });

    dispatch({
      type: ActionType.UPDATE_RANGE_FOR_BAR_VISUAL,
      payload: value,
    });
  };
};

export const updateGroup = (group: string) => {
  return {
    type: ActionType.UPDATE_GROUP,
    payload: group,
  };
};

export const updateLayout = (layout: "horizontal" | "vertical") => {
  return {
    type: ActionType.UPDATE_LAYOUT,
    payload: layout,
  };
};


export const updatePieRange = (range:number[]) => {
  return {
    type: ActionType.UPDATE_PIE_CHART_RANGE,
    payload: range,
  };
}

export const barChartLayoutUpdate = (layout:number) => {
  return {
    type: ActionType.UPDATE_BAR_LAYOUT,
    payload: layout,
  };
};

export const adjustPadding = (value:number) => {
  return {
    type: ActionType.UPDATE_PADDING_FOR_BAR_CHART,
    payload: value,
  };
}


export const barChartFontUpdate = (fontSize:number) => {
  return {
    type: ActionType.UPDATE_FONT_SIZE,
    payload: fontSize,
  };

}

export const lowHighSwitchAction = (layout: string | undefined) => {
  return {
    type: ActionType.LOW_HIGH_SWITCH,
    payload: layout,
  };
};

export const updateAnswerState = (state: string) => {
  return {
    type: ActionType.UPDATE_ANSWER_STATE,
    payload: state,
  };
};

export const setSubscribedQuestionCurrentState = (index: number | string) => {
  return {
    type: ActionType.SELECTED_PREVIOUS_QUESTION_INDEX,
    payload: index,
  };
};

export const startAnswerLoad = () => {
  return (dispatch: Dispatch) => {
    dispatch(updateAnswerState("Fetch"));
  };
};

export const completeAnswerLoad = () => {
  return (dispatch: Dispatch) => {
    dispatch(updateAnswerState("View"));
  };
};

export const changeCurrentVisual = (visual: any) => {
  return {
    type: ActionType.UPDATE_CURRENT_VISUAL,
    payload: visual,
  };
};

export const updateCurrentVisualJobId = (job_id: string, compute_route: string | undefined) => {
  return {
    type: ActionType.UPDATE_CURRENT_VISUAL_JOB_ID_AND_COMPUTE_ROUTE,
    payload: {
      job_id,
      compute_route,
    },
  };
};

export const clearAnswer = () => {
  return {
    type: ActionType.CLEAR_ANSWER,
  };
};

export const prevAskFailed = () => {
  return {
    type: ActionType.PRE_ASK_FAILED,
  };
};

export const getPreviousQuestion = (value: string) => {
  return async (dispatch: Dispatch, getState: ()=>RootState) => {
    const state: RootState = getState();
    const fetch_history = state.answer.fetch_history;
    const subscribed_id = state.answer.subscribedQuestionUtterenceId;
    try {
      dispatch(updateAnswerState("Fetch"));
      const response = await getPreviousAnswer(value);
      const question = response.body.utterance;
      const utteranceId = response.body.utteranceId;
      const subscribed = true;
      const visual = response.body.visual;
      const isHistory = false;
      const time_stamp = response.body.time_stamp;
      dispatch(
        hydrateAnswerAction({
          question,
          utteranceId,
          visual,
          subscribed,
          subscribed_id,
          fetch_history,
          isHistory,
          time_stamp,
        })
      );
      dispatch(currentQuestionTimestamp(time_stamp));
      dispatch(updateAnswerState("View"));
    } catch (err) {
      dispatch(updateAnswerState("View"));
      dispatch(alert("Selected answer is not update please try again later."));
    }
  };
};


export const isOpenEditForm = (value: boolean) => {
  return {
    type: ActionType.EDIT_FORM_OPEN_OR_CLOSE,
    payload: value,
  };
};

export const isLoadingAliasAction = (value: boolean) => {
  return {
    type: ActionType.IS_LOADING_ALIAS,
    payload: value
  }
}

export const searchAliasList = (page?: number, isAlias?:boolean) => {
  try {
    return async (dispatch: Dispatch) => {
      if(isAlias){
        dispatch(aliasLoaderTable(true))
      }else{
        dispatch(isLoadingAliasAction(true));
      }

      const data = await getAlias(page, PAGE_LIMIT);
      if (data.status === 200) {
        dispatch({
          type: ActionType.GET_LISTING_RESPONSE,
          payload: data.body.intent_alias,
        });
        dispatch({
          type: ActionType.SEARCHED_TOTAL_RECORDS,
          payload: data.body.total_records,
        });
        dispatch(isLoadingAliasAction(false));
        dispatch(aliasLoaderTable(false))
      }else if(data.status === 500){
        dispatch(isLoadingAliasAction(false));
        dispatch(aliasLoaderTable(false))
        dispatch(alert(data.body.error));
        return;
      }
      dispatch(isLoadingAliasAction(false));
    };
  } catch (err) {
    console.log(err);
  }
};


export const updateAliasAndRun = (data: any) => {
  try {
    return async (dispatch: Dispatch) => {
      const response = await updateAlias(data);
      if (response.status === 200) {
        const { error, status} = response.body
        if(status) {
          dispatch({
            type: ActionType.UPDATE_ALIAS_SUCCESS_RE_RUN
          });
        } else {
          dispatch({
            type: ActionType.UPDATE_ALIAS_ERROR,
            payload: error,
          });
        }
      }
    }
  } catch (err) {}
};
export const updateAliasAndChat= (data: any, query: string) => {
  try {
    return async (dispatch: Dispatch, getState:()=>RootState) => {
      const state: RootState = getState()
      const response = await updateAlias(data);
      if (response.status === 200) {
        const { error, status} = response.body
        const chatId = state.chat.current_thread_details?.id;
        const focus = state.agent?.selectedCommand?.display;
        const sources = state.agent?.selected_sources;
        if(status && chatId) {
          const payload:sendNewMessagePayload = {
            chat_id: chatId,
            is_new_chat: true,
            sources: sources||[],
            focus: state.agent?.selectedCommand?.is_visible ? focus || "" : "",
            message_intent: "question",
            user_message: {
              author: {
                role: "user",
                tool: "",
                metadata: "",
              },
              content:{
                type:"text",
                message:query||"",
                metadata:""
              },
              metadata: "",
            },
            message_id: "",
            question_id: "",
          };
          //@ts-ignore
          dispatch(sendMessageAction(payload))

          if (data.sendMessage && !error) {
            // dispatch(
            //   messageSentAction({
            //     q: query,
            //     chatId,
            //     messageId: data.sendMessage.id,
            //   })
            // );
            // const utterancePayload = {
            //   chatId,
            //   utterance: query,
            //   respondingToMessageId: data.sendMessage.messageId,
            // };

            // const additionalHeaders = {
            //   "refresh": await getRefreshToken(),
            // };
            // await API.graphql(graphqlOperation(processUtterance, utterancePayload),additionalHeaders);
          }

          dispatch(isOpenEditForm(false))
          dispatch(toggleAnswerModalAction(false))
        } else {
          dispatch({
            type: ActionType.UPDATE_ALIAS_ERROR,
            payload: error,
          });
        }
      }
    }
  } catch (err) {}
};

export const saveAliasAsNewAndRun =  (data: any) => {
  try {
    return async (dispatch: Dispatch) => {
      const response = await createAlias(data);
      if (response.status === 200) {
        const { error, status} = response.body
        if(status) {
          dispatch({
            type: ActionType.ADD_NEW_ALIAS_SUCCESS_RE_RUN,
            payload: {
              //@ts-ignore
              rule_name: data.rule_name
            },
          });
        } else {
          const attachRuleName = error.replace('rule_name already exist',`rule_name: ${data.rule_name} already exist`)
          dispatch({
            type: ActionType.UPDATE_ALIAS_ERROR,
            payload: attachRuleName,
          });
        }
      }
    }
  } catch (err) {}
};

export const saveAliasAsNewAndChat =  (data: any, query: string) => {
  try {
    return async (dispatch: Dispatch, getState: ()=>RootState) => {
      const state = getState()
      const response = await createAlias(data);
      if (response.status === 200) {
        const { error, status} = response.body
        const chatId = state.chat.current_thread_details?.id;
        const focus = state.agent?.selectedCommand?.display;
        const sources = state.agent?.selected_sources;
        if(status && chatId) {
          const payload:sendNewMessagePayload = {
            chat_id: chatId,
            is_new_chat: true,
            sources: sources||[],
            focus: state.agent?.selectedCommand?.is_visible ? focus || "" : "",
            message_intent: "question",
            user_message: {
              author: {
                role: "user",
                tool: "",
                metadata: "",
              },
              content:{
                type:"text",
                message:query||"",
                metadata:""
              },
              metadata: "",
            },
            message_id: "",
            question_id: "",
          };
          //@ts-ignore
          dispatch(sendMessageAction(payload))

          // if (data.sendMessage && !error) {
            // dispatch(
            //   messageSentAction({
            //     q: query,
            //     chatId,
            //     messageId: data.sendMessage.id,
            //   })
            // );
            // const utterancePayload = {
            //   chatId,
            //   utterance: query,
            //   respondingToMessageId: data.sendMessage.id,
            // };

            // const additionalHeaders = {
            //   "refresh": await getRefreshToken(),
            // };
            // await API.graphql(graphqlOperation(processUtterance, utterancePayload),additionalHeaders);
          // }
          dispatch(isOpenEditForm(false))
          dispatch(toggleAnswerModalAction(false))
        } else {
          const attachRuleName = error.replace('rule_name already exist',`rule_name: ${data.rule_name} already exist`)
          dispatch({
            type: ActionType.UPDATE_ALIAS_ERROR,
            payload: attachRuleName,
          });
        }
      }
    }
  } catch (err) {}
};

export const showLoaderOnSubmit = (value: boolean) => {
  return {
    type: ActionType.SHOW_LOADER_ON_SUBMIT_ALIAS,
    payload: value
  }
}

export const setSelectedRowIdAction = (id: string) => {
  return {
    type: ActionType.SET_AND_UPDATE_ALIAS_ROW_ID,
    payload: id
  }
}
export const updateAliasAction = (data:any) => {
  try {
    return async (dispatch: Dispatch<any>) => {
      dispatch(showLoaderOnSubmit(true));
      const res = await updateAlias(data);
      if(res.status === 200){
        dispatch({
          type: ActionType.UPDATED_ALIAS_LIST,
          payload: data,
        });
        dispatch(showLoaderOnSubmit(false));
      }
      dispatch(showLoaderOnSubmit(false));
    }
  }catch(error){
   console.log(error);
  }
}
export const insertAliasData = (data: any) => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch(showLoaderOnSubmit(true));
      const response = await createAlias(data);
      if (response.status === 200) {
        data._id = response.body.id;
        const { error, status} = response.body
        if(status) {
          dispatch({
            type: ActionType.ADD_NEW_ALIAS_SUCCESS,
            payload: {
              //@ts-ignore
              rule_name: data.rule_name
            },
          });
          dispatch({
            type: ActionType.ALIAS_UPDATED_BY_QUESTION
          })
          dispatch({
            type: ActionType.ADD_NEW_ALIAS_DATA,
            payload: data
          })
          dispatch(alert(`Rule Added!`,  { position: "top-right" },"success"));
          dispatch(showLoaderOnSubmit(false));
        } else {
          dispatch(alert(`Rule Added Failed! ${error}`,  { position: "top-right" },"error"));
          dispatch({
            type: ActionType.UPDATE_ALIAS_ERROR,
            payload: error,
          });
        }
      }
      dispatch(showLoaderOnSubmit(false));
    };
  } catch (err) {}
};

//delete alias records
export const deleteAliasRecord = (rule_name: any, selectedRowsId: Array<any>) => {
  try {
    return async (dispatch: Dispatch) => {
      const response =  await deleteAlias(rule_name);
      dispatch({
        type: ActionType.DELETE_ALIAS_IN_PROGRESS
      })

      if (response.status === 200) {
        dispatch({
          type: ActionType.DELETE_ALIAS_SUCCESS
        })
        dispatch({
          type: ActionType.DELETE_SELECTED_ALIAS_FROM_LIST,
          payload: selectedRowsId
        })
        dispatch(alert("Rule deleted!",  { position: "top-right" }));
      }else{
        dispatch({
          type: ActionType.DELETE_ALIAS_FAILED
        })
      }
    };
  } catch (err) {
    console.log(err);
  }
};

export const displayMessage =()=>{
  try{
    return async (dispatch: Dispatch) => {
      dispatch(alert("Rule Added!",  { position: "top-right" },"success"));
    }
  }catch(error){
    console.log("");
  }
}
//update alias form records
export const updateAliasRecords = (data: any) => {
  try {
    return async (dispatch: Dispatch) => {
      const response = await updateAlias(data);
      if (response.status == 200) {
        dispatch({
          type: ActionType.UPDATE_ALIAS_SUCCESS,
          payload: {
            //@ts-ignore
            rule_name: data.rule_name
          },
        });
        dispatch(aliasUpdateSpinner(false));
        const resp = await getAlias();
        dispatch({
          type: ActionType.GET_LISTING_RESPONSE,
          payload: resp.body,
        });
      }else{
        dispatch(aliasUpdateSpinner(true));
      }
    };
  } catch (err) {
    console.log(err);

  }
};

export const getAliasForAnswer = (rule_name: string) => {
  return async (dispatch: Dispatch) => {
    try{
      const response = await getAliasByName(rule_name)
      if(response.body){
        dispatch(isQueryFormLoader(false))
      }

    }catch(err){
      console.log(err)
    }
  }
}

export const isQueryFormLoader = (value:boolean) => {
  return{
    type: ActionType.IS_ALIAS_FORM_LOADER,
    payload: value
  }
}

export const getAliasByNameAction = (rule_name: string) => {
  try {
    return async (dispatch: Dispatch) => {
      const response = await getAliasByName(rule_name);
      if (response.status == 200) {
        dispatch({
          type: ActionType.UPDATED_ALIAS,
          payload: response.body
        })
      }
    };
  } catch (err) {
    console.log(err);
  }
}

export const fetchAliasSchema = () => {
  try {
    return async (dispatch: Dispatch) => {
      dispatch({
        type: ActionType.GET_ALIAS_SCHEMA
      })
      const { body: { alias_types } } = await getAliasSchema();
      dispatch({
        type: ActionType.UPDATE_ALIAS_SCHEMA,
        payload: useFixture? allSchema.alias_types : alias_types 
      })
        // dispatch(isQueryFormLoader(false))
    };
  } catch (err) {
    console.log(err);
  }
}

export const aliasDeleteSpinner = (data:boolean) =>{
  return {
    type: ActionType.ALIAS_SPINNER_ON_DELETE,
    payload: data,
  };
}

export const aliasUpdateSpinner = (data:boolean) =>{
  return {
    type: ActionType.ALIAS_SPINNER_ON_UPDATE,
    payload: data,

  }
}
export const updateVisualTypeNone = () => {
  return {
            type: ActionType.UPDATE_VISUAL_TYPE,
          payload: {type:"none"}
    }      
 }

 export const toggleDropdown = (value:boolean) => {
  return {
          type: ActionType.TOGGLE_DROPDOWN,
          payload: value
    }      
 }

 export const visibleCondition = (value:boolean) => {
  return {
          type: ActionType.TOGGLE_VISIBLE,
          payload: value
    }      
 }



export const tableSliderData = (value:any) => {
return {
  type: ActionType.TABLE_SLIDER_SET_RANGE,
  payload: value
}

}

export const showTextAbbreviated = (value:boolean)=> {
  return {
    type: ActionType.SHOW_ABBREVIATED_TEXT,
    payload: value
  }
}

 export const isAddColumn = (value:boolean) => {
  return {
          type: ActionType.IS_ADD_COLUMN,
          payload: value
    }      
 }

export const selectTableColumn = (value:string)=> {
  return{
    type: ActionType.SELECTED_TABLE_COLUMN,
    payload: value
  }
}
export const setVisibleColumn = (value:string) => {
  return {
    type: ActionType.SET_VISIBLE_COLUMNS,
    payload: value
  }
}
 export const handleReadMoreToggle = (value: boolean) => {
  return {
    type: ActionType.UPDATE_READMORE_LESS_TOGGLE_VISIBLE,
    payload: value
  }
 }
 export const tableHeaderSuggestions = (searchResults:any,inputSearchValue:string) => {
  try {
    return async (dispatch: Dispatch , getState: ()=>RootState) => {
      const state = getState()
      const utteranceId = (state.answer.visual as any).utteranceId
      const data = await tableSuggestions(
        utteranceId,
        inputSearchValue,
        searchResults,
      );

      if (data.status === 200) {
        dispatch(updateSearchSuggestion(data.body));
        dispatch(showSuggestionResponse(false))
      }
    };
  } catch (err) {
    console.log(err);
  }
};

export const showSuggestionResponse = (data: boolean) => {
  return {
    type: ActionType.IS_SUGGESTION_RESPONSE,
    payload: data,
  }
}

export const updateSearchSuggestion = (data:any) => {
  return {
    type: ActionType.TABLE_SEARCH_SUGGESTION,
    payload: data,
  }
}



 export const headerSearch = (value:string) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.SEARCH_TABLE_HEADER_VALUE,
      payload: value,
    });
 }}

 export const handlePopUpVisible = (data:boolean) => {
  return {
    type: ActionType.UPDATE_POP_UP_VISIBLE,
    payload: data,
  }
}

export const toggleAnswerSidebar = () => {
  return { 
    type: ActionType.TOGGLE_ANSWER_SIDEBAR
  }
}

export const questionCopy = (input: any) => {
  return async(dispatch: Dispatch) => {
    try{
     if(input){
      dispatch(alert(`Copied to clipboard!`,  { position: "bottom-left" },"success"));
     }
    }
    catch(err){
      dispatch(alert(`Copied Failed! ${err}`,  { position: "bottom-left" },"error"));
    }
  }
}

export const updateSelectedOptions = (options: any) => ({
  type: ActionType.SET_SELECTED_OPTIONS,
  payload: options,
});

export const updateExportInProgress = (status: boolean) => {
  return {
      type: ActionType.UPDATE_EXPORT_IN_PROGRESS,
      payload: status
  }
}

export const setInputSearchValue = (searchValue: any) => {
  return {
    type: ActionType.UPDATE_INPUT_SEARCH_VALUE_TERM,
    payload: searchValue
  }
}
export const setIsTableSearchInputCleared = (isCleared: any) => {
  return {
    type: ActionType.SEARCH_VALUE_CLEARED,
    payload: isCleared
  }
}

export const searchIndex = (index:any) => {
  return {
    type: ActionType.ADD_SEARCH_INDEXES,
    payload: index
  }
}

export const updateBarChartLayout = (utteranceId:string, layout:"horizontal" | "vertical") => {
  return {
    type: ActionType.UPDATE_CHART_OPTIONS,
    payload: {utteranceId, layout}
  }
}

export const aliasTableSearchAction = (value:string) => {
  return{
    type: ActionType.ALIAS_TABLE_SEARCH_TERM,
    payload: value
  }
}

export const aliasTableElasticSearch = (searchTerm:string,setCurrentPage?:any, currentPage?:any) => {
  return async (dispatch: Dispatch) => {
    try{
      dispatch(aliasLoaderTable(true))
      const param = {
        is_search_header:false,
        searchQuery:searchTerm,
        limit:200,
        page:currentPage
      }
      const response = await aliasSearch(param);
      if(response.status === 200){
        dispatch(aliasLoaderTable(false))
        dispatch({
          type: ActionType.GET_LISTING_RESPONSE,
          payload: response.body.intent_alias,
        });
        dispatch({
          type: ActionType.SEARCHED_TOTAL_RECORDS,
          payload: response.body.total_records,
        });
        setCurrentPage(currentPage);
      }

    }catch(err){
      console.log(err)
    }
  }
}

export const aliasSearchSuggestionAction = (query:any, header:any) => {
  return async(dispatch: Dispatch)=>{
    try{
      const response = await aliasSuggestion(query, header)
      if(response.status===200){
        dispatch(setIsSearchProgressAction(false))
        dispatch(suggestionListingAction(response.body))
      }
    }catch(err){
      console.log(err)
    }
  }
}


export const setIsSearchProgressAction =(value:boolean) => {
  return{
    type: ActionType.IS_SEARCH_PROGRESS,
    payload: value
  }
}
export const suggestionListingAction =(value:any) => {
  return{
    type: ActionType.SUGGESTION_LISTING,
    payload: value
  }
}

export const setSuggestionSearchAction = (list:any)=>{
  return{
    type: ActionType.SUGGESTION_SEARCH_LIST,
    payload: list
  }
}


export const setAliasSearchTermAction =(value:string) => {
  return{
    type:ActionType.ALIAS_SEARCH_TERM,
    payload:value
  }
}


export const aliasSearchOperator = (operator:any) => {
  return{
    type: ActionType.ALIAS_SEARCH_OPERATOR,
    payload: operator
  }
}

export const aliasSearchWithSuggestionAction = (value?:any, operator?:string, currentPage?:number, setCurrentPage?:any) => {
  return async (dispatch: Dispatch, getState: ()=>RootState) => {
      try{
        dispatch(aliasLoaderTable(true))
          let operators:string|undefined = '';

          if(operator){
              operators=operator
          }else{
              operators = getState().answer.aliasOperator;
          }
        
          const searchSpecialChars = /(?:=|!=|:|!:)/
          let contains:any=[];
          let equals:any=[];
          

          if(value){
              value.map((item: any) => {
                  const [key, value] = item.split(searchSpecialChars);
                  const formatKey = keyMap[key]
                  const matches = item.match(searchSpecialChars);
                  if (matches && (matches[0] === "=" || matches[0] === "!=")) {
                    dispatch(aliasEqualsSearchInputAction(equals))
                      return equals.push([formatKey,value, matches[0]]);
                  } else if (matches && (matches[0] === ":" || matches[0] === "!:"))
                     dispatch(aliasContainsSearchInputAction(contains))
                      return contains.push([formatKey, value, matches[0]]);
              });
          }
          else{
              equals = getState().answer.aliasEqualsInput;
              contains = getState().answer.aliasContainsInput;
          }
      
          //these two dispatch are not used for now because it will just store value and in future we get this value to export pdf according to search
          // dispatch(setAnalyzerContainsSearch(contains))
          // dispatch(setAnalyzerEqualsSearch(equals))

          const payload:any = {
              "contains":contains,
              "equals":equals,
              "is_search_header":true,
              "l_operator":operators,
              "limit":200,
              'page': currentPage

          }
              const result:any = await aliasSearch(payload);
              if(result?.status === 200){

                dispatch(aliasLoaderTable(false))
                dispatch({
                  type: ActionType.SEARCHED_TOTAL_RECORDS,
                  payload: result.body.total_records,
                });
                
                  dispatch({
                    type: ActionType.GET_LISTING_RESPONSE,
                    payload: result.body.intent_alias,
                  });
                  setCurrentPage(currentPage)
                 
              }
      }catch(err){
          console.log(err, "error here")
      }
  }
}

export const aliasContainsSearchInputAction = (contains:Array<string>)=> {
  return{
    type: ActionType.ALIAS_CONTAINS_SEARCH_INPUT,
    payload: contains
  }
}

export const aliasEqualsSearchInputAction = (equals:Array<string>)=> {
  return{
    type: ActionType.ALIAS_EQUALS_SEARCH_INPUT,
    payload: equals
  }
}

export const setHeaderAction = (value:string) => {
  return{
    type:ActionType.SEARCH_ALIAS_HEADER_VALUE,
    payload: value
  }
}


export const aliasLoaderTable = (value:boolean)=>{
  return{
    type: ActionType.ALIAS_LOADER_TABLE,
    payload: value
  }
}

export const updateElasticIndexed = (value: boolean) => {
  return {
    type: ActionType.CHECK_AND_UPDATE_ELASTIC_INDEXED,
    payload: value
  }
}

export const updateTableIndexedStatusAction = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try{
      const {tableSearchIndexStatus} = getState().answer
      const {is_elastic_indexed}: any = getState().answer.visual
      if(tableSearchIndexStatus==="SUCCEEDED"||tableSearchIndexStatus==="FAILED"||is_elastic_indexed){
        return;
      }

      const u_id = getState().answer.utteranceId;
      const res = await fetchingTableIndexedStatus(u_id);

      const {visual,utteranceId} = getState().answer
      if(res.status === 200){
        const getStatus = res.body.status;
        dispatch({
          type: ActionType.UPDATE_TABLE_SEARCH_INDEXED,
          payload: getStatus
        })
        if(getStatus === "SUCCEEDED"){
          dispatch(updateElasticIndexed(true));
          dispatch(updateUserHistoryEntry( utteranceId ,{...visual,is_elastic_indexed:true}))
          return;
        }
        else if(getStatus === "FAILED"){
          dispatch(updateElasticIndexed(false));
          dispatch(updateUserHistoryEntry( utteranceId ,visual))
          dispatch(alert(res.body.error));
          return;
        }

      }else if(res.status === 500 || res.status === 404 ){
        dispatch(updateElasticIndexed(false));
        dispatch(updateUserHistoryEntry( utteranceId ,visual))
        dispatch(alert(res.body.error));
        dispatch({
          type: ActionType.UPDATE_TABLE_SEARCH_INDEXED,
          payload: "FAILED"
        })
        return;
      }
    }catch(error){
      console.log(error,"Error message");
    }
  }
}

export const resetIndexStaus = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ActionType.UPDATE_TABLE_SEARCH_INDEXED,
      payload: " "
    })
  }
}


export const setAnswerSubscriptionAction = (payload: {
  name: string;
  value: any;
}) => {
  return {
    type: ActionType.SET_ANSWER_SUBSCRIPTION,
    payload,
  };
};

export const setTableChips = (chips:Array<string>=[]) => {
  return {
    type: ActionType.UPDATE_TABLE_CHIPS,
    payload:chips
  }
}