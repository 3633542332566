import { get, post, Response, delete_req, patch, put } from "makeRequest";
import { AddQuestionResponse, CreateTab, DeleteTab, QuickLinksNew, QuicklinksCategoryCreationResponse, TabsResponse } from "types/quickLinks";

type CommonResponse = {
  message: string,
  status: string,
  error:string,
}

export function addNewContext(data: any): Response<CreateTab> {
  return post('/create-tab', data);
}

export function getUserContextTabs(): Response<TabsResponse> {
  return get('/get-tabs')
}

export function fetchQuickLinks(): Response<QuickLinksNew> {
  return get('/quick-links-new')
}

export function removeUserContext(id: string): Response<DeleteTab> {
  return delete_req(`/delete-tab/${id}`)
}

export function createCategory(value: any): Response<QuicklinksCategoryCreationResponse> {
  return post('/create-quick-links-category', value)
}

export function deleteSelectedCategory(id: string | number): Response<CommonResponse> {
  return delete_req(`/delete-quick-links-category/${id}`)
}

export function updateCategoryTitle(data: any): Response<CommonResponse> {
  return put(`/update-quick-links-category`, data)
}

export function fetchSelectedTabData(id: string): Response<QuickLinksNew> {
  return get(`/quick-links-new?current_tab_id=${id}`)
}

export function createNewQuestion(data: object): Response<AddQuestionResponse> {
  return post(`/add-quick-links-question`, data)
}

export function updateContextLabel(data: object): Response<CommonResponse> {
  return post(`/update-app-settings`, data)
}

export function updateQuestionOrder(data: object): Response<CommonResponse> {
  return post(`/update-quick-links-question`, data)
}

export function renameQuickLinkQuestion(data: object): Response<CommonResponse> {
  return patch(`/rename-quick-links-question`, data)
}


export function updateContextTabFields(data: object): Response<CommonResponse> {
  return put('/update-tab', data)
}

export function deleteSelectedQs(id: any): Response<CommonResponse> {
  return delete_req('/delete-quick-links-question', id)
}


export function updateTabPosition(data: object): Response<CommonResponse> {
  return patch(`/update-tab-position`, data)
}

export function updateQsStatus(data: any): Response<CommonResponse> {
  return patch('/change-question-flag', data)
}

export const askQuestionSetIntoChat = (data: any): Response<CommonResponse> => {
  return post('/submit-question-set', data)
}