import { quickLinkTabColors } from 'utils/ThemeColors/quickLinkTabColors'
import { actionBarColors } from 'utils/ThemeColors/actionBarColors'
import { chatColors } from 'utils/ThemeColors/chat/chatColors'
import { configurationColors } from 'utils/ThemeColors/configurationColors'
import { leftNavigationColors } from 'utils/ThemeColors/leftNavigationColors'
import { newWorkerColor} from 'utils/ThemeColors/newWorkerColor'
import { notificationPageColors } from 'utils/ThemeColors/notificationPageColors'
import { operationLayerColors } from 'utils/ThemeColors/operationLayerColors'
import { recentlySidebarColors } from 'utils/ThemeColors/recentlySidebarColors'
import { recentlyTopQuestionColors } from 'utils/ThemeColors/recentlyTopQuestionColors'
import { reportTableColors } from 'utils/ThemeColors/reportTableColors'
import { rightNavigationColors } from 'utils/ThemeColors/rightNavigationColors'
import { rocketAnalyzerColors } from 'utils/ThemeColors/rocketAnalyzerColors'
import { subscribedQuestionCalenderColors } from 'utils/ThemeColors/subscribedQuestionCalenderColors'
import { subscriptionColors } from 'utils/ThemeColors/subscriptionColors'
import { answerPieChartColor } from 'utils/ThemeColors/answerPieChartColor'
import { emailHeaderSectionColors } from 'utils/ThemeColors/emailHeaderSectionColors'
import { newAnswerVisualColors } from 'utils/ThemeColors/newAnswerVisualColors'
import { webHookEventsColors } from 'utils/ThemeColors/webHookEventsColors'
import { auditLogsColors } from 'utils/ThemeColors/auditLogsColors'
import { editQuickLinkDashboardColors } from 'utils/ThemeColors/editQuickLinkDashboardColors'
import { compareVariationColors } from 'utils/ThemeColors/compareVariationColors'
import { miscellaneousColors } from 'utils/ThemeColors/miscellaneousColors'
import { scrollBarCustomColor } from 'utils/ThemeColors/scrollBarCustomColor'
import { upConfigSelectColors } from 'utils/ThemeColors/upConfigSelectColors'
import { newTopHeaderColors } from 'utils/ThemeColors/newTopHeaderColors'
import { chatSidebarColors } from 'utils/ThemeColors/chat/chatSidebarColors'
import { chatInputColors } from 'utils/ThemeColors/chat/chatInputColors'
import { suggestionInputColors } from 'utils/ThemeColors/attachedV2SuggestionColors'
import { chatConfigColors } from 'utils/ThemeColors/chatConfigAgentsFormColors'
import { citatoinColors } from 'utils/ThemeColors/citatioinColors'

export const darkTheme = {
    mode: "dark",
    isDarkMoade: true,
    isDarkMode: true,
    //edit query alias
    SubFormText: '#fff',

    //chat color
    ...chatColors.dark,

    // LeftNavigation COLORS
    ...leftNavigationColors.dark,

    // RightNavigation COLORS
    ...rightNavigationColors.dark,

    // ActionBar Color
    ...actionBarColors.dark,

    // Notification Page Color
    ...notificationPageColors.dark,

    // NEW WORKER COLORS
    ...newWorkerColor.dark,

    //OPERATION LAYER COLORS
    ...operationLayerColors.dark,

    //rocket-analyzer
    ...rocketAnalyzerColors.dark,

    //Configration section
    ...configurationColors.dark,

    //SUBSCRIPTION FEATURE COLORS
    ...subscriptionColors.dark,

    // REPORT TABLE STYLING
    ...reportTableColors.dark,

    //SUBSCRIBED QUESTION CALENDAR COLORS
    ...subscribedQuestionCalenderColors.dark,
   
    //Recently top questions
    ...recentlyTopQuestionColors.dark,

    //Recently Sidebar
   ...recentlySidebarColors.dark,

    //Quick Links Tabs
    ...quickLinkTabColors.dark,

    ...scrollBarCustomColor.dark,

    //Answer Pie Chart Background Color
    ...answerPieChartColor.dark,

    //EmailHeaderSection
    ...emailHeaderSectionColors.dark,

    // colour code for newAnswer visual
    ...newAnswerVisualColors.dark,

    //webhook events
    ...webHookEventsColors.dark,
    //audit logs
    ...auditLogsColors.dark,
    //edit quicklinks dashbord
    ...editQuickLinkDashboardColors.dark,

    //compare variation feature
    ...compareVariationColors.dark,

    // up config select colors
    ...upConfigSelectColors.dark,

    //new Top header colors
    ...newTopHeaderColors.dark,

    ...miscellaneousColors.dark,

    // new Chat side bar colors
    ...chatSidebarColors.dark,

    ...chatInputColors.dark,

    ...suggestionInputColors.dark,

    //Chat config AgentFormColors
    ...chatConfigColors.dark,

    //Citation page colors
    ...citatoinColors.dark,
}

export const lightTheme = {
    mode: "light",
    isDarkMoade: false,
    isDarkMode:false,
    //edit query alias
    SubFormText: '#969696',


    ...chatColors.light,


    // LeftNavigation COLORS
    ...leftNavigationColors.light,


    // RightNavigation COLORS
    ...rightNavigationColors.light,

    // ActionBar Color
    ...actionBarColors.light,

    // Notification Page Color
    ...notificationPageColors.light,

    // NEW WORKER COLORS
    ...newWorkerColor.light,

    //OPERATION LAYER COLORS
    ...operationLayerColors.light,

    //Rocket Anaylzer
    ...rocketAnalyzerColors.light,

    //Configration section
    ...configurationColors.light,

    //SUBSCRIPTION FEATURE COLORS
    ...subscriptionColors.light,


    // REPORT TABLE STYLING
    ...reportTableColors.light,


    //SUBSCRIBED QUESTION CALENDAR COLORS 
    ...subscribedQuestionCalenderColors.light,

    //Recently asked questions
    ...recentlyTopQuestionColors.light,

    //Recently Sidebar
    ...recentlySidebarColors.light,

    //Quick Links Tabs
    ...quickLinkTabColors.light,


    //Answer Pie Chart Background Color
    ...answerPieChartColor.light,

    ...scrollBarCustomColor.light,

    //EmailHeaderSection
    ...emailHeaderSectionColors.light,

    // colour code for newAnswerVisual
    ...newAnswerVisualColors.light,

    ...webHookEventsColors.light,
    //audit logs
    ...auditLogsColors.light,
    //edit quicklinks dashbord
    ...editQuickLinkDashboardColors.light,

    // Compare variation feature
    ...compareVariationColors.light,

    // up config select colors
    ...upConfigSelectColors.light,

    //new Top header colors
    ...newTopHeaderColors.light,

    ...miscellaneousColors.light,

    // new Chat side bar colors
    ...chatSidebarColors.light,

    ...chatInputColors.light,

    ...suggestionInputColors.light,

    //Chat config AgentFormColors
    ...chatConfigColors.light,

     //Citation page colors
     ...citatoinColors.light,
}
