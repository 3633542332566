import { chatThread } from "./chatv2";
import { Author, Delta, MessageContent, ProcessingStatus, UserMessage } from "./message";

export enum EventType {
  // thread level events
  THREAD_CREATED = "thread_created",
  THREAD_RENAMED = "thread_renamed",
  THREAD_CLOSED = "thread_closed",
  THREAD_JOINED = "thread_joined",
  // message level events
  THREAD_MESSAGE_INITIALIZING = "thread_message_initializing",
  THREAD_MESSAGE_PROCESSING = "thread_message_processing",
  THREAD_MESSAGE_DONE = "thread_message_done",
  THREAD_MESSAGE_FAILED = "thread_message_failure",
  THREAD_MESSAGE_BLOCKED = "thread_message_blocked",
  // message fragment level events
  THREAD_MESSAGE_SUB_MESSAGE_INITIALIZING = "thread_message_sub_message_initializing",
  THREAD_MESSAGE_SUB_MESSAGE_PROCESSING = "thread_message_sub_message_processing",
  THREAD_MESSAGE_SUB_MESSAGE_DONE = "thread_message_sub_message_done",
  THREAD_MESSAGE_SUB_MESSAGE_FAILURE = "thread_message_sub_message_failure",
  THREAD_MESSAGE_SUB_MESSAGE_RETRY = "thread_message_sub_message_retry",
  KNOWLEDGE_BASE_STATUS_UPDATE = "knowledge_base_status_update",
  CHAT_CONFIG_MODIFIED = "chat_config_modified",
  CUSTOMER_NOTICE_UPDATED = "customer_notice_updated",
}

export type EventTypes =
  // thread level events
  | "thread_created"
  | "thread_renamed"
  | "thread_closed"
  | "thread_joined"
  // message level events
  | "thread_message_initializing"
  | "thread_message_processing"
  | "thread_message_done"
  | "thread_message_failure"
  | "thread_message_blocked"
  // message fragment level events
  | "thread_message_sub_message_initializing"
  | "thread_message_sub_message_processing"
  | "thread_message_sub_message_done"
  | "thread_message_sub_message_failure"
  | "thread_message_sub_message_retry"
  | "knowledge_base_status_update"
  | "chat_config_modified"
  | "customer_notice_updated";

export type MessageMetadata = {
  focus: string;
  sources: string[];
  answer_version_number: number;
  message_intent: "question" | "private";
  processing_status: ProcessingStatus;
  user_message: UserMessage;
};

export type SubmessageMetadata = {
  id: string;
  metadata: string;
  readout_title: string;
  answer_version_number: number;
  processing_status: ProcessingStatus;
  delta: Delta;
};

export type EventData = {
  id: string;
  question_id: string;
  answer_id: string;
  created_at: number;
  recepient: "user" | "system";
  is_visually_hidden: Boolean;
  question_version_number: number;
  message_metadata: MessageMetadata;
  sub_message_metadata: SubmessageMetadata;
};

export type Event = {
  event_type: EventTypes;
  user_id: string[];
  id: string;
  created_at: number;
  author: Author;
  thread_metadata: chatThread;
  content: MessageContent;
  event_metadata: {
    event_message: string;
    event_data: EventData;
  };
};
