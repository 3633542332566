import { ActionType } from "state/action-types"
import { Dispatch } from "redux";
import { RootState } from "state";
import { addNewContext, createCategory, createNewQuestion, deleteSelectedCategory, deleteSelectedQs, fetchQuickLinks, 
    fetchSelectedTabData, getUserContextTabs, removeUserContext, updateCategoryTitle, updateContextLabel, updateContextTabFields ,
    updateQuestionOrder ,renameQuickLinkQuestion ,updateTabPosition, updateQsStatus

} from "services/EditQuickLinkDashboard";
import { UpdateContextTabs } from "components/QuickLinks/ToggleModeDashbord/type";
import { alert } from "./customRouter";

export const showTextAreaAction = (value: boolean) => {
    return {
        type: ActionType.HIDE_AND_SHOW_TEXTAREA,
        payload: value
    }
}

export const updateSingleAskedQuestionIndexAction = (question_id:string)  => {
    return {
        type: ActionType.UPDATE_SINGLE_ASKED_QUESTION_INDEX,
        payload: question_id
    }
}

export const updateGroupAskedQuestionIndexAction = (group_id:string)  => {
    return {
        type: ActionType.UPDATE_GROUP_ASKED_QUESTION_INDEX,
        payload: group_id
    }
}

export const editToggleMode = (value:boolean) => {
return{
    type: ActionType.IS_TOGGLE_EDIT_MODE,
    payload: value,
}
}
export const isOpenContextBox = ( value:boolean) => {
    return{
        type: ActionType.IS_OPEN_ADD_NEW_CONTEXT_BOX,
        payload: value,
    }
}
export const isOpenContextLabelBox = ( value:boolean) => {
    return{
        type: ActionType.IS_OPEN_EDIT_CONTEXT_LABEL,
        payload: value,
    }
}


export const addNewUserContextTab = (data: any) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await addNewContext(data);
            if (response.status === 201) {
                dispatch(isEditModeButtonLoader(false));
                dispatch(isOpenContextBox(false))
                dispatch(updateEditModeUserContext(response.body.data))
                dispatch(alert(response.body.message, {}, 'success'));
            } else {
                dispatch(isEditModeButtonLoader(false));
                dispatch(alert(response.body.error));
            }
        } catch (err) {
            console.log(err, "error")
        }
    }
}


export const updateEditModeUserContext= (data: any) => {
    return{
        type: ActionType.UPDATE_EDIT_MODE_USER_CONTEXT,
        payload: data,
    }

}

export const deleteSelectedContext = (tabId: string) => {
    return async (dispatch: Dispatch<any>, getState: () => RootState) => {
      try {
        const {status, body} = await removeUserContext(tabId);

        if (status === 200) {
            dispatch({
                type: ActionType.REMOVE_SELECTED_CONTEXT_TAB,
                payload: tabId,
            });
            const {userContextTabs, activeTabId} = getState().quickLinksEdit;
            if (activeTabId === tabId) {
                const tab_id = userContextTabs[0].id;
                await dispatch(fetchSelectedTabLinks(tab_id));
                await dispatch(selectedTabId(tab_id));
            }
            dispatch(removeContextTabAction(true));
          }else{
              dispatch(removeContextTabAction(false));
              return dispatch(alert((body as any)?.error||body.message))
          }
          dispatch(removeContextTabAction(false));
          dispatch(alert(body.message))
      } catch (err) {
        console.log(err);
      }
    };
  };

export const removeContextTabAction = (value: boolean) => {
    return {
        type: ActionType.REMOVE_AND_UPDATE_QUICK_LINK_DASHBOARD,
        payload: value
    }
}
export const getEditableUserContextTabs = () => {
    return async (dispatch: Dispatch) => {
        try{
            dispatch(userContextLoader(true))
            const response = await getUserContextTabs();
            if(response.status === 200){
                dispatch(userContextLoader(false))
                dispatch(updatedContextLabelAction(response.body.tab_main_heading))
                dispatch({
                    type: ActionType.USER_CONTEXT_TABS,
                    payload: response.body.tabs,
                  });
            }
        }catch(err){
            console.log(err)
        }
    }
}



export const isContextLoader =(value:boolean) => {
    return {
        type: ActionType.IS_EDIT_DASHBOARD_CONTEXT_LOADER,
        payload: value,

    }
}

export const userContextLoader = (value: boolean) => {
    return {
        type: ActionType.INITIAL_USER_CONTEXT_LOADER,
        payload: value
    }
}
export const setIsNewCardOpen = (value : boolean) => {
    return {
        type: ActionType.SET_EDIT_CATEGORY_CARD_IS_OPEN,
        payload: value
    }
}


export const isAddNewQs = (value:boolean) => {
    return{
        type: ActionType.IS_QUICK_LINKS_ADD_QUESTION,
        payload: value,
    }
}

export const fetchQuicklinks = () => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await fetchQuickLinks();
            if (response.status === 200) {
                const categories = response.body.categories;
                const current_tab_id  = response.body.current_tab_id;
                dispatch(updateQuickLinks(categories));
                dispatch(selectedTabId(current_tab_id));
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export const updateQuickLinks = (quickLinksList:any) => {
    return{
        type: ActionType.QUICK_LINKS_DASHBOARD,
        payload: quickLinksList,
    }
}


export const removeCategoryAction = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            dispatch({
                type: ActionType.DELETE_SELECTED_CATEGORY_CARD,
                payload: id,
            })
            const { body, status } = await deleteSelectedCategory(id);
            if (status === 200) {
                dispatch(alert(body.message, {}, 'success'));
            } else {
                dispatch(alert(body.error));
            }
        } catch (err) {
            console.log(err)

        }
    }
}


export const editCategoryHeaderAction = (title?:string|number, id?:string, color?:string)=>{
    return async (dispatch:Dispatch, getState: () => RootState)=>{
        try{
            const state = getState();
            const isInternal = state.auth.user.internal;
            const quickLinklist = state.quickLinksEdit.quickLinks;
              const data = {
                    category_id: id,
                    new_title: title,
                    new_color:color
                }


            if(isInternal){
                const {status,body} = await updateCategoryTitle(data);
                if(status===200){
                    const updatedQuickLinks = quickLinklist.map((item: any)=>{
                        if(item.id === id){
                          return{
                            ...item,
                            title: title,
                            header_color:color
                          }
                        }else{
                            return item
                        }
                    })
                    dispatch(updateQuickLinks(updatedQuickLinks));
                    dispatch(alert(body.message, {position:'bottom-left'}, 'success'));
                }else{
                    dispatch(alert(body?.error));
                }
            }

        }catch(err){
            console.log(err)
        }
    }
}

export const fetchSelectedTabLinks = (id: string) => {
    return async (dispatch:Dispatch)=>{
        try{
            dispatch(isContextLoader(true))
          const response = await fetchSelectedTabData(id);
          if(response.status === 200){
            const quickLinksList = response.body.categories
            dispatch(updateQuickLinks(quickLinksList))
            dispatch(isContextLoader(false))
          }else if(response.status === 500){
            dispatch(isContextLoader(false));
          }
        }catch(err){
            console.log(err)
        }
    }
}

export const createNewCategoryAction = (selectedVal: string, newTitle: string | number, color: string) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const selectedTabId = getState().quickLinksEdit.activeTabId;
            const quickLinklist = getState().quickLinksEdit.quickLinks;
            const length = quickLinklist.length + 1;

            const payload = {
                title: newTitle,
                tab_id: selectedTabId,
                category_type: selectedVal,
                header_color: color,
                position: length,

            }
            const response = await createCategory(payload);
            if (response.status === 201 || response.status === 200) {
                dispatch(isEditModeButtonLoader(false))
                dispatch(setIsNewCardOpen(false))
                const category_id: string = response.body?.category_id
                const param = [{
                    category_type: selectedVal,
                    header_color: color,
                    id: category_id,
                    position: length,
                    suggestions: [],
                    title: newTitle
                }]
                const updateQuickLinkCards = [...quickLinklist, ...param];
                dispatch(updateQuickLinks(updateQuickLinkCards))
                dispatch({
                    type: ActionType.QUICK_LINKS_NEW_CATEGORY_ID,
                    payload: category_id,
                })
                dispatch(alert(response.body.message, {}, "success"));
            } else if (response.body.error) {
                dispatch(isEditModeButtonLoader(false))
                dispatch(alert(response.body.error))
            }
        } catch (err) {
            console.log(err)

        }
    }
}

export const selectedTabId = (id:string|number)=> {
    return{
        type: ActionType.SELECTED_TAB_ID,
        payload: id,
    }
}

export const addQuicklinksQsAction = (title:string|object, id:string, group_title?:string, agent?: string | null, focus?: string | null,sources?: string[], setIsQsBoxOpen?: any) => {
   return async (dispatch:Dispatch, getState: () => RootState) => {
    try{
        const state = getState()
        let updatedQuickLinkList = []
        const quickLinks = state.quickLinksEdit.quickLinks
        const tab_id = state.quickLinksEdit.activeTabId
        const data = {
            tab_id: tab_id,
            category_id: id,
            question: title,
            agent: agent,
            focus: focus,
            sources: sources,
            ...(group_title ? { group_name: group_title} : {}),
            ...(group_title ? {category_type:'group'}:{})
        }

        const response = await createNewQuestion(data)
        if(response.status === 201){
            dispatch(isEditModeButtonLoader(false))
            dispatch(isQuestionAddBtnLoaderAction(false))
            setIsQsBoxOpen(false);
            const questionId = response.body.question_id
            const questionData= {
                is_working:true,
                question: title,
                id:questionId,
                is_changed: false,
                agent: agent,
                focus: focus,
                sources: sources
            }
            const groupQuestiondata = {
                id: response.body?.id,
                group_name: group_title,
                questions:[{
                    is_working:true,
                    question: title,
                    id:questionId,
                    is_changed: false,
                    agent: agent,
                    focus: focus,
                    sources: sources
                }]
            }

            if (group_title) {
                let groupExists = false;
                updatedQuickLinkList = quickLinks.map((item:any) => {
                    if (item.id === id) {
                        const updatedSuggestions = item?.suggestions?.map((suggestion:any) => {
                            if (suggestion.group_name === group_title) {
                                groupExists = true;
                                return {
                                    ...suggestion,
                                    questions: [...suggestion.questions, questionData],
                                };
                            }
                            return suggestion;
                        });

                        if (!groupExists) {
                            updatedSuggestions.push({
                                ...groupQuestiondata
                            });
                        }

                        return {
                            ...item,
                            suggestions: updatedSuggestions,
                        };
                    } else {
                        return item;
                    }
                });
            } else {
                updatedQuickLinkList = quickLinks.map((item:any) => {
                    if (item.id === id) {
                        return {
                            ...item,
                            suggestions: [...item?.suggestions, questionData], // Append questionData
                        };
                    } else {
                        return item;
                    }
                });
            }
            dispatch(updateQuickLinks(updatedQuickLinkList));
            setTimeout(() => dispatch(alert(response.body.message, {}, "success")));
        }else{
            dispatch(isEditModeButtonLoader(false))
            dispatch(isQuestionAddBtnLoaderAction(false))
            dispatch(alert(response.body.error))
        }
    }catch(err){
        dispatch(isEditModeButtonLoader(false))
        dispatch(isQuestionAddBtnLoaderAction(false))
        console.log(err)
    }
   }
}


export const updatedIndexChangeList = (data?: any, items?: any) => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            const state = getState();
            const isInternal = state.auth.user.internal;
            dispatch(updateQuickLinks(items))
            const payload = {
                position_changes: data,
            }
            dispatch({
                type: ActionType.MODIFIED_QUICK_LINKS_WITH_INDEX_CHANGED,
                payload: data,
            })
            if (isInternal) {
                const {status, body} =  await updateCategoryTitle(payload);
                if (status !== 200) {
                    dispatch(alert(body.error));
                }
            }
        } catch (err) {
            console.log(err)
        }
    }
}


export const editContextLabelAction = (data: string) => {
    return async (dispatch: Dispatch, getState: any) => {
        try {
            const id = getState().siteSettings.id;
            const payload = {
                newsettings: {
                    action: "tab_main_heading",
                    tab_main_heading: data,
                },
                _id: id
            }
            const { body, status } = await updateContextLabel(payload)

            if (status === 200) {
                dispatch(updatedContextLabelAction(data))
                dispatch(alert(body.message, {}, 'success'));
            } else {
                dispatch(alert(body.error));
            }
        } catch (err) {
            console.log(err)
        }
    }
}

export const updatedContextLabelAction=(data:string)=>{
    return {
        type: ActionType.GET_UPDATED_CONTEXT_LABEL,
        payload: data
    }
}

export const IsEditableContextTabAction = (value:boolean)=>{
    return {
        type: ActionType.IS_EDIT_USER_CONTEXT_TABS,
        payload: value
    }
}


export const isEditQsStatusLayout = (value:boolean)=>{
    return {
        type: ActionType.IS_EDIT_QUICKLINK_QUESTION_STATUS,
        payload: value
    }
}


export const updateUserContextTabAction = (data:UpdateContextTabs, index:number) =>{
    const vivibilityOptionIndex = index + 1;
    const updatedName = data.name;
    return async (dispatch:Dispatch, getState: () => RootState) => {
        try{
            const userContextTabs = getState().quickLinksEdit.userContextTabs;
            const updatedTabs = userContextTabs.map((item)=>{
                if(item.id === data.tab_id){
                    return {
                        ...item,
                        name:updatedName,
                        visibility: vivibilityOptionIndex,
                        group:data.group
                    }
                }
                return item;
            })
            dispatch({
                type: ActionType.USER_CONTEXT_TABS,
                payload: updatedTabs,
              });
            const { status, body } = await updateContextTabFields(data);
            if (status === 200) {
                dispatch(alert(body.message, {}, 'success'));
            } else {
                dispatch(alert(body.error));
            }
        } catch(err) {
            console.log(err)
        }
    }
}

export const updateLeftActiveQuickLinkQuestionIndex = (value: number) => {
    return {
        type: ActionType.UPDATE_LEFT_ACTIVE_QUICK_QUESTION_INDEX,
        payload: value
    }
}
export const updateLeftActiveQuickLinkIndex = (value: number) => {
    return {
        type: ActionType.UPDATE_LEFT_ACTIVE_QUICK_LINK_INDEX,
        payload: value
    }
}
export const updateLeftActiveQuickLinksSuggestionIndex = (value: number) => {
    return {
        type: ActionType.UPDATE_LEFT_ACTIVE_QUICK_SUGGESTION_INDEX,
        payload:value
    }
    }

export const isEditModeButtonLoader = (value:boolean) =>{
    return {
        type: ActionType.IS_EDIT_MODE_BUTTON_LOADER,
        payload: value
    }
}


export const updateGroupOrderAction = (data: any , category_id: string, is_question_set?: boolean) => {
    return async (dispatch: Dispatch) => {

        try {
             const group_payload =  {
                "category_id": category_id,
                "is_group_update": true,
                "questions": data.map((group: any) => ({
                    "id": group.id,
                    "group_name": group.group_name,
                    "question_ids": group.questions.map((question: any) => question.id),
                    "agent": "",
                    "focus": "",
                    "sources": []
                  }))
                }

                 const question_set_payload =  {
                    "category_id": category_id,
                    "is_group_update": true,
                    "questions": data.map((group: any) => ({
                        "id": group.id,
                        "group_name": group.group_name,
                        "question_ids": group.questions.map((question: any) => question.id),
                        "agent": group.questions[0].agent ?? "",
                        "focus":group.questions[0].focus ?? "",
                        "sources": group.questions[0].sources ?? [],
                      }))
                    }
          updateQuestionOrder(is_question_set ? question_set_payload : group_payload);

            dispatch({
                type: ActionType.UPDATE_SUGGESTIONS_WITH_CATEGORY_ID,
                payload: {
                    category_id: category_id,
                    suggestions: data
                }
            })
        } catch (error) {
            console.log("error" , error);
            dispatch(alert())
        }
    }
}


export const deleteGroupAction = ( category_id: string , group_id: string, is_question_set?: boolean) => {
    return async (dispatch: Dispatch , getState:  () => RootState) => {
        try {
            const category_data: any = (getState()).quickLinksEdit.quickLinks.find((item: any) => item.id == category_id);
            const new_data = category_data.suggestions.filter((item: any) => item.id != group_id)

             const payload =  {
                "category_id": category_id,
                "is_group_update": true,
                "questions": new_data.map((group: any) => ({
                    "id": group.id,
                    "group_name": group.group_name,
                    "question_ids": group.questions.map((question: any) => question.id),
                    "agent": "",
                    "focus": "",
                    "sources": []
                  }))
                }
             const question_set_payload =  {
                "category_id": category_id,
                "is_group_update": true,
                "questions": new_data.map((group: any) => ({
                    "id": group.id,
                    "group_name": group.group_name,
                    "question_ids": group.questions.map((question: any) => question.id),
                    "agent": group.questions[0].agent ?? "",
                    "focus": group.questions[0].focus ?? "",
                    "sources": group.questions[0].sources ?? []
                  }))
                }

       await  updateQuestionOrder(is_question_set ? question_set_payload : payload);

        dispatch({
            type: ActionType.UPDATE_SUGGESTIONS_WITH_CATEGORY_ID,
            payload: {
                category_id: category_id,
                suggestions: new_data
            }
        })
        } catch (error) {
            console.log("error", error)
            dispatch(alert())
        }
    }
}


const updateQuickLinkGroupQuestionLoaderAction = (value:boolean) => {
    return {
        type: ActionType.UPDATE_GROUP_QUICK_LINK_LOADER,
        payload: value
    }
}


export const renameCategoryAction = (group_name_new: string , category_id: string , group_id: string, is_question_set?: boolean) => {
    return async (dispatch: Dispatch , getState:  () => RootState) => {
        try {
            const category_data: any = (getState()).quickLinksEdit.quickLinks.find((item: any) => item.id == category_id)

            const new_data =   category_data.suggestions.map((suggestions: any) => {
                if(suggestions.id == group_id) {
                 suggestions.group_name = group_name_new
                }
                return suggestions
            })

            const group_payload =  {
                "category_id": category_id,
                "is_group_update": true,
                "questions": new_data.map((group: any) => ({
                    "id": group.id,
                    "group_name": group.group_name,
                    "question_ids": group.questions.map((question: any) => question.id),
                    "agent": "",
                    "focus": "",
                    "sources": []
                  }))
                }
             const question_set_payload =  {
                "category_id": category_id,
                "is_group_update": true,
                "questions": new_data.map((group: any) => ({
                    "id": group.id,
                    "group_name": group.group_name,
                    "question_ids": group.questions.map((question: any) => question.id),
                    "agent": group.questions[0].agent ?? "",
                    "focus":group.questions[0].focus ?? "",
                    "sources": group.questions[0].sources ?? [],
                  }))
                }

        dispatch(updateQuickLinkGroupQuestionLoaderAction(true));

        const res = await updateQuestionOrder(is_question_set ? question_set_payload : group_payload);

            dispatch({
                type: ActionType.UPDATE_SUGGESTIONS_WITH_CATEGORY_ID,
                payload: {
                    category_id: category_id,
                    suggestions: new_data
                }
            })
        if(res.status == 200){
            dispatch(showTextAreaAction(false));
            dispatch(updateGroupAskedQuestionIndexAction(''));
        }else{
            dispatch(alert(res.body?.error));
        }
        dispatch(updateQuickLinkGroupQuestionLoaderAction(false));
        } catch (error) {
            console.log("error", error)
            dispatch(showTextAreaAction(false));
            dispatch(updateQuickLinkGroupQuestionLoaderAction(false));
        }
    }
}

export const updateGroupQuestionOrderAction = (data: any, category_id: string, group_name: string, is_question_set: boolean) => {
    return async (dispatch: Dispatch, getState:  () => RootState) => {
        try {
            const category_data: any = (getState()).quickLinksEdit.quickLinks.find((item: any) => item.id == category_id);

            const new_data = category_data.suggestions.map((suggestions: any) => {
                if (suggestions.group_name == group_name) {
                    suggestions.questions = data
                }
                return suggestions
            })

            const group_payload =  {
                "category_id": category_id,
                "is_group_update": true,
                "questions": new_data.map((group: any) => ({
                    "id": group.id,
                    "group_name": group.group_name,
                    "question_ids": group.questions.map((question: any) => question.id),
                    "agent": "",
                    "focus": "",
                    "sources": []
                  }))
                }
             const question_set_payload =  {
                "category_id": category_id,
                "is_group_update": true,
                "questions": new_data.map((group: any) => ({
                    "id": group.id,
                    "group_name": group.group_name,
                    "question_ids": group.questions.map((question: any) => question.id),
                    "agent": group.questions[0].agent ?? "",
                    "focus":group.questions[0].focus ?? "",
                    "sources": group.questions[0].sources ?? [],
                  }))
                }
            updateQuestionOrder(is_question_set  ? question_set_payload : group_payload);

            dispatch({
                type: ActionType.UPDATE_SUGGESTIONS_WITH_CATEGORY_ID,
                payload: {
                    category_id: category_id,
                    suggestions: new_data
                }
            })
        } catch (error) {
            console.log("error", error);
            dispatch(alert())
        }
    }
}

export const updateQuestionOrderAction = (data: any , category_id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const ids = data.map((item: any) => item.id);
            const payload = {
                "category_id": category_id,
                "is_group_update": false,
                "questions": ids,
                "agent": "",
                "focus": "",
                "sources": []
            }
            const {status, body} = await updateQuestionOrder(payload);
            if (status === 200) {
                dispatch({
                    type: ActionType.UPDATE_SUGGESTIONS_WITH_CATEGORY_ID,
                    payload: {
                        category_id: category_id,
                        suggestions: data
                    }
                })
                dispatch(alert(body.message, {}, "success"))
            } else {
                dispatch(alert(body.error))
            }
        } catch (error) {
            console.log("error" , error);
            dispatch(alert())
        }
    }
}
export const isQuestionAddBtnLoaderAction = (value:boolean) =>{
    return {
        type: ActionType.IS_ADD_QUESTION_BUTTON_LOADER,
        payload: value
    }
}

export const deleteSelectedQuestionAction = (categoryId: string, questionId: string, group_name?:string | undefined) => {
    return async (dispatch: Dispatch, getState:  () => RootState) => {
      try {
        const quickLinks = getState().quickLinksEdit.quickLinks;

        const params_for_single = {
            category_id: categoryId,
            question_id: questionId
        };
        const params_for_group = {
            category_id: categoryId,
            question_id: questionId,
            group_name: group_name
        };

        const {status, body} = await deleteSelectedQs(group_name !== undefined ? params_for_group : params_for_single);

        if (status === 200) {
          dispatch(isRemoveQsLoaderAction(false));

          const updatedQuickLinks = quickLinks.map((category: any) => {
            if (category.id === categoryId) {
              if (category.category_type === "group") {
                // Handle group category
                category.suggestions.forEach((group: any) => {
                  if (group.group_name === group_name) {
                    // Delete the question with the specified ID within the matching group
                    group.questions = group.questions.filter(
                      (question: any) => question.id !== questionId
                    );
                  }
                });
              } else {
                // Handle single category
                category.suggestions = category.suggestions.filter(
                  (question: any) => question.id !== questionId
                );
              }
            }
            return category;
          });

          dispatch(updateQuickLinks(updatedQuickLinks));
          setTimeout(() => dispatch(alert(body.message, {}, "success")));
        } else {
            dispatch(alert(body.error))
        }
    } catch (err) {
        console.log(err);
      }
    };
  };

const updateQuickLinkQuestionLoaderAction = (value:boolean) => {
    return {
        type: "update_quick_link_loader",
        payload: value
    }
}


export const renameQuickLinkQuestionAction = (question: string , question_id: string , category_id: string, agent: string | null, focus: string | null, sources: string[] ) => {
    return async (dispatch: Dispatch , getState:  () => RootState) => {
        try {
            const tab_id = (getState()).quickLinksEdit.activeTabId;
            const payload = {
                tab_id: tab_id,
                new_title: question,
                question_id: question_id,
                agent: agent,
                focus: focus,
                sources: sources
            }

            dispatch(updateQuickLinkQuestionLoaderAction(true));
            const {status, body} = await renameQuickLinkQuestion(payload);

            if(status == 200) {
                dispatch({
                    type: ActionType.UPDATE_QUESTION_WITH_CATEGORY_ID_QUESTION_ID,
                    payload: {
                        question: question,
                        question_id: question_id,
                        category_id: category_id,
                        agent: agent,
                        focus: focus,
                        sources: sources
                    }
                })
                dispatch(updateQuickLinkQuestionLoaderAction(false));
                dispatch(updateSingleAskedQuestionIndexAction(''));
                dispatch(showTextAreaAction(false));
                dispatch(alert(body.message, {}, "success"))
            }else{
                dispatch(showTextAreaAction(false));
                dispatch(updateQuickLinkQuestionLoaderAction(false));
                dispatch(alert(body?.error));
            }

        } catch (error) {
            console.log("error", error)
            dispatch(alert())
        }
    }
}

export const renameQuickLinkQuestionForGroupAction = (question: string , question_id: string , category_id: string , group_name: string, agent: string | null, focus: string | null, sources: string[]) => {
    return async (dispatch: Dispatch , getState:  () => RootState) => {
        try {
            const tab_id = (getState()).quickLinksEdit.activeTabId;
            const payload = {
                tab_id: tab_id,
                new_title: question,
                question_id: question_id,
                agent: agent,
                focus: focus,
                sources: sources,
            }
            dispatch(handleLoaderOnEditModeTextareaAction(true));
            const response = await renameQuickLinkQuestion(payload);

            if(response.status == 200) {
                dispatch({
                    type: ActionType.UPDATE_QUESTION_WITH_CATEGORY_ID_QUESTION_ID_GROUP_NAME,
                    payload: {
                        group_name: group_name,
                        question: question,
                        question_id: question_id,
                        category_id: category_id,
                        agent: agent,
                        focus: focus,
                        sources: sources
                    }
                })
                dispatch(handleLoaderOnEditModeTextareaAction(false));
                dispatch(updateSingleAskedQuestionIndexAction(''));
                dispatch(showTextAreaAction(false));
            }else{
                dispatch(handleLoaderOnEditModeTextareaAction(false));
                dispatch(updateSingleAskedQuestionIndexAction(''));
                dispatch(alert(response.body?.error));
            }

        } catch (error) {
            console.log("error", error)
            dispatch(alert())
        }
    }
}



export const isRemoveQsLoaderAction = (value:boolean) =>{
    return {
        type: ActionType.IS_REMOVE_QUESTION_LOADER,
        payload: value
    }
}



export const updateTabPositionAction = (updated_data: any, data: any) => {
    return async (dispatch: Dispatch) => {
        try {
            const result = updateTabPosition(data);
            // here not waiting for response to finish so that update is happen quickly
            dispatch({
                type: ActionType.USER_CONTEXT_TABS,
                payload: updated_data
            })
            const response = await result;
            if(response.status===403){
                dispatch(alert(response?.body?.error));
            }
        } catch (error) {
            console.log("error", error)
        }
    }
}

export const updateQuestionStatus = (data: any, category_id: any, type: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const {body, status} = await updateQsStatus(data)

            if (status === 200) {
                dispatch(isQuestionAddBtnLoaderAction(false))
                const payload = {
                    question_id: data.question_id,
                    status: data.target_flag,
                    category_id: category_id,
                }
                if (type === 'single') {
                    dispatch(updateQuicklinksQsStatus(payload))
                } else {
                    dispatch(updateQuickLinksGroupQsStatus(payload))
                }
                dispatch(isEditQsStatusLayout(false))
                dispatch(isEditQuestionStatus(false))
                dispatch(alert(body.message, {}, "success"))
            } else {
                dispatch(alert(body?.error));
            }
        } catch (error) {
            console.log(error, "error")
        }
    }
}

export const updateQuicklinksQsStatus =(data:any)=>{
    return{
        type: ActionType.UPDATE_QUICK_LINK_QS_STATUS,
        payload:data
    }
}

export const updateQuickLinksGroupQsStatus = (data:any) => {
    return{
        type: ActionType.UPDATE_QUICK_LINK_GROUP_QS_STATUS,
        payload:data
    }
}

export const isEditQuestionStatus = (value:boolean)=> {
    return{
        type: ActionType.IS_EDIT_QUESTION_STATUS,
        payload: value
    }
}

export const handleLoaderOnEditModeTextareaAction = (value: boolean) => {
    return {
        type: ActionType.UPDATE_QUICK_LINK_LOADER,
        payload: value
    }
}

export const isDragDropEnabledAction = (value:boolean)=>{
    return{
        type: ActionType.IS_DRAG_DROP_ENABLED,
        payload:value
    }
}

export const selectedGroupNameAction = (value: string) => {
    return {
        type: ActionType.UPDATE_SELECTED_GROUP_NAME,
        payload: value
    }
}