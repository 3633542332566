import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useActions } from 'hooks/use-actions';
export default function useRouteChangeEffect() {
    const location = useLocation();
    const [ searchParams ] = useSearchParams();
    const {
        showQuickLinkContext,
        updateAnswerState,
        showAnswerOnSubscribedAnswer,
        compareQuestions,
        setSubscribeIndex,
        updateVisualTypeNone,
        updateActiveQuickLinkIndex,
        tableSearchTermUpdate,
        tableSliderData,
        setInputSearchValue,
        handleCompareAnswer,
        toggleAnswerModalAction,
        focusChatInput,
        resetChatThreadAction,
        setValue,
        setChatQueryStringAction,
      } = useActions();

    const chatPaths = [ "/show-operations" ,"/show-up-response", "/operation-analyzer-layer"];
    useEffect(() => {
        const newPath = window.location.pathname;
        if(!chatPaths.includes(newPath)){
            tableSearchTermUpdate("")
            setSubscribeIndex(-1);
            compareQuestions(false);
            updateVisualTypeNone();
            showQuickLinkContext();
            updateActiveQuickLinkIndex(-1);
            showAnswerOnSubscribedAnswer(false);
            toggleAnswerModalAction(false);
            updateAnswerState("view")
            tableSliderData([]);
            setInputSearchValue("");
            handleCompareAnswer(false);
            focusChatInput();
            resetChatThreadAction()
            setValue("");
            setChatQueryStringAction({q:""});
        }
    }, [location.pathname,searchParams]);
}